.bg-pending {
  background-color: rgb(253 187 56 / 0.5);
  border-color: #fdbb38;
  border-radius: 30px 0px;
}
.bg-approve  {
  background-color: rgb(25 175 251 / 0.2);
  border-color: #19affb;
  border-radius: 30px 0px;
}
.bg-reject {
  background-color: rgb(244 104 65 / 0.2);
  border-color: #f46841;
  border-radius: 30px 0px;
}
.bg-revision  {
  background-color: rgb(110 107 250 / 0.2);
  border-color: #6e6bfa;
  border-radius: 30px 0px;
}
.bg-pending .db-icon {
    background-color: #fdbb38;
}
.bg-approve .db-icon {
    background-color: #19affb;
}
.bg-reject .db-icon {
    background-color: #f46841;
}
.bg-revision .db-icon {
    background-color: #6e6bfa;
}
@media only screen and (min-width: 992px){
.mini-sidebar .page-wrapper {
    margin-left: 78px;
} 
}
 




.mini-sidebar.false .page-wrapper {
  margin-left: 78px;
}





