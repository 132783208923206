.main_wrapper {
  background-color: #f1f1f1;
  padding: 1rem;
  margin-top: 1rem;
}
.item {
  padding: 1rem;
  background-color: white;
  border: 1px solid black;
  cursor: move;
}
.item.dragging {
  opacity: 0.5;
}
.file-upload {
  background-color: #ffffff;
  width: 600px;
  margin: 0 auto;
  padding: 20px;
}
.image-upload-wrap {
  margin-top: 20px;
  border: 4px dashed #1fb264;
  position: relative;
}
.drag-text {
  text-align: center;
}
.file-upload-btn {
  margin-top: 20px;
  margin-bottom: 40px;
  width: 31%;
  color: #fff;
  background: #1fb264;
  border: none;
  padding: 10px 8px;
  border-radius: 4px;
  border-bottom: 4px solid #15824b;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}
.dropdown-menu.show {
  display: block;
}
.btn-outline-primary {
  color: #ffbc53;
  border-color: #ffbc53;
}
.download-link:hover {
  background-color: yellow !important;
  color: white !important;
}
.download-link:hover .icon {
  color: white;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  /* background: white; */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
