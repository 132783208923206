.main {
  width: 100%;
  height: auto;
  padding: 20px;
}
.title_subtext {
  font-size: 20px;
}
.paragraph_text {
  font-size: 18px;
}
.label_text {
  font-size: 20px;
}
.college_logo {
  width: 350px !important;
  height: 300px !important;

  /* alignitems: center; */
  /* margin-left: 74rem;
  marginbottom: 4px; */
}
.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
/* .table_input{
    border: none;
} */
.file-upload {
  background-color: #ffffff;
  width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.file-upload-btn {
  margin-top: 20px;
  margin-bottom: 40px;
  width: 31%;
  color: #fff;
  background: #0b7b83;
  border: none;
  padding: 10px 8px;
  border-radius: 4px;
  border-bottom: 4px solid #0b7b83;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.file-upload-btn:hover {
  background: #0b7b83;
  color: #ffffff;
  transition: all 0.2s ease;
  cursor: pointer;
}

.file-upload-btn:active {
  border: 0;
  transition: all 0.2s ease;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  margin-top: 20px;
  border: 4px dashed #0b7b83;
  position: relative;
}

.image-dropping,
.image-upload-wrap:hover {
  background-color: #0b7b83;
  border: 4px dashed #ffffff;
}

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}

.drag-text {
  text-align: center;
}

.drag-text h3 {
  font-weight: 100;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #03bac7;
  padding: 10px 0;
}

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}

.remove-image {
  width: 200px;
  margin: 0;
  color: #fff;
  background: #cd4535;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #b02818;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.remove-image:hover {
  background: #c13b2a;
  color: #ffffff;
  transition: all 0.2s ease;
  cursor: pointer;
}

.remove-image:active {
  border: 0;
  transition: all 0.2s ease;
}
.custom-file {
  position: relative;
  font-family: helvetica;
  overflow: hidden;
  margin-bottom: 30px;
  margin-top: 30px;
  width: auto;
  display: block;
  padding: 10px;
}

.custom-file-input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: #fff;
  background: #1fb264;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #15824b;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.custom-file img {
  vertical-align: middle;
  margin-right: 10px;
}

ul.file-list {
  font-family: helvetica;
  list-style: none;
  padding: 0;
}

ul.file-list li {
  padding: 10px;
  background-color: #1df883;
  font-size: 1rem;
  position: relative;
  margin: 10px;
}

.remove-list {
  cursor: pointer;
  margin-left: 10px;
  position: absolute;
  right: 20px;
}

#selectedFilesD img,
#selectFilesM img {
  max-width: 200px;
  max-height: 200px;
  float: left;
  margin-bottom: 10px;
}
#userActions input {
  width: auto;
  margin: auto;
}
#selectFiles img,
#selectedFilesM img {
  max-width: 200px;
  max-height: 200px;
  float: left;
  margin-bottom: 10px;
}
/* input[type='date']::-webkit-datetime-edit-text { */
/* color: transparent; */
/* This hides the default placeholder in Chrome */
/* } */
