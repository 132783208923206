.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the alpha value for transparency */
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-upload {
  background-color: #ffffff;
  width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.file-upload-btn {
  margin-top: 20px;
  margin-bottom: 40px;
  width: 31%;
  color: #fff;
  background: #0b7b83;
  border: none;
  padding: 10px 8px;
  border-radius: 4px;
  border-bottom: 4px solid #0b7b83;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.file-upload-btn:hover {
  background: #0b7b83;
  color: #ffffff;
  transition: all 0.2s ease;
  cursor: pointer;
}

.file-upload-btn:active {
  border: 0;
  transition: all 0.2s ease;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  margin-top: 20px;
  border: 4px dashed #0b7b83;
  position: relative;
}

.image-dropping,
.image-upload-wrap:hover {
  background-color: #0b7b83;
  border: 4px dashed #ffffff;
}

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}

.drag-text {
  text-align: center;
}

.drag-text h3 {
  font-weight: 100;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #03bac7;
  padding: 10px 0;
}

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}

.remove-image {
  width: 200px;
  margin: 0;
  color: #fff;
  background: #cd4535;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #b02818;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.remove-image:hover {
  background: #c13b2a;
  color: #ffffff;
  transition: all 0.2s ease;
  cursor: pointer;
}

.remove-image:active {
  border: 0;
  transition: all 0.2s ease;
}

#selectedFilesD img,
#selectFilesM img {
  max-width: 200px;
  max-height: 200px;
  float: left;
  margin-bottom: 10px;
}
#userActions input {
  width: auto;
  margin: auto;
}
#selectFiles img,
#selectedFilesM img {
  max-width: 200px;
  max-height: 200px;
  float: left;
  margin-bottom: 10px;
}
.cross {
  background-color: #fff;
  float: right;
  font-size: 1.5rem;
  /* font-weight: 700; */
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
