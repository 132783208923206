.right_panel {
  padding-left: 90px;
  padding-right: 80px;
}
.form_main,
.form_footer_text,
.footer_link,
.form_title input {
  font-size: 14px;
}
/* input{
    height: 32px;
} */
.form-control {
  font-size: 14px !important;
}
.form-label {
  color: #990000;
}
.input_label:hover {
  color: #575b60 !important;
}
.sign_btns {
  padding: 2px 13px 3px 13px;
  border-radius: 4px;
}
a {
  text-decoration: none;
  color: #263791;
}
a:hover {
  color: red;
}
.text {
  font-size: 14px;
  color: #999999;
}
@media only screen and (max-width: 1400px) {
  .footer_border {
    padding-inline: 0px !important;
  }
}

@media only screen and (max-width: 768px) {
  .right_panel {
    margin: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .right_panel {
    padding-left: 50px !important;
    padding-right: 45px !important;
  }
}
@media only screen and (max-width: 990px) {
  .right_panel {
    padding-left: 25px !important;
    padding-right: 0px !important;
  }
}

.form-control:focus {
  border: 3px solid #1f1f1f;
  box-shadow: none;
}
/* .form-control {
  border-radius: 0px;
} */
